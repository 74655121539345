"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDateDef = void 0;
function parseDateDef() {
    return {
        type: "string",
        format: "date-time",
    };
}
exports.parseDateDef = parseDateDef;
