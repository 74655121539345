"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseObjectDef = void 0;
const parseDef_1 = require("../parseDef");
function parseObjectDef(def, refs) {
    var _a;
    const result = Object.assign(Object.assign({ type: "object" }, Object.entries(def.shape()).reduce((acc, [propName, propDef]) => {
        if (propDef === undefined || propDef._def === undefined)
            return acc;
        const parsedDef = (0, parseDef_1.parseDef)(propDef._def, Object.assign(Object.assign({}, refs), { currentPath: [...refs.currentPath, "properties", propName], propertyPath: [...refs.currentPath, "properties", propName] }));
        if (parsedDef === undefined)
            return acc;
        return {
            properties: Object.assign(Object.assign({}, acc.properties), { [propName]: parsedDef }),
            required: propDef.isOptional()
                ? acc.required
                : [...acc.required, propName],
        };
    }, { properties: {}, required: [] })), { additionalProperties: def.catchall._def.typeName === "ZodNever"
            ? def.unknownKeys === "passthrough"
            : (_a = (0, parseDef_1.parseDef)(def.catchall._def, Object.assign(Object.assign({}, refs), { currentPath: [...refs.currentPath, "additionalProperties"] }))) !== null && _a !== void 0 ? _a : true });
    if (!result.required.length)
        delete result.required;
    return result;
}
exports.parseObjectDef = parseObjectDef;
